import React, { useEffect, useState, Suspense, lazy } from 'react';
import './CSS/Product.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import NotFound from '../Components/NotFound/NotFound';

// Lazy load components
const Breadcrum = lazy(() => import('../Components/Breadcrums/Breadcrum'));
const ProductDisplay = lazy(() => import('../Components/ProductDisplay/ProductDisplay'));
const DescriptionBox = lazy(() => import('../Components/DescriptionBox/DescriptionBox'));
const RelatedProducts = lazy(() => import('../Components/RelatedProducts/RelatedProducts'));
const ContactUs = lazy(() => import('../Components/ContactUs/ContactUs'));

const Product = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [selectedSize, setSelectedSize] = useState('S');
  const [error, setError] = useState(null);

  const sizeMap = {
    S: '100 gm',
    M: '250 gm',
    L: '500 gm',
    XL: '1 Kg',
  };
  const reverseSizeMap = Object.fromEntries(
    Object.entries(sizeMap).map(([key, value]) => [value, key])
  );

  const handleSizeChange = (size) => {
    // Convert size from descriptive to shorthand before setting it
    const backendSize = reverseSizeMap[size];
    if (backendSize) {
      setSelectedSize(backendSize);
    } else {
      console.error('Invalid size selected:', size);
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(
          `https://jaipurmasale-backend.onrender.com/api/get-product-detail/?product_pk=${productId}&size=${selectedSize}`
        );
        setProductData(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching product data:', error);
        setError('Failed to load product data. Please try again later.');
      }
    };

    fetchProductData();
  }, [productId, selectedSize]);

  if (error) {
    return <NotFound />;
  }

  if (!productData) {
    return (
      <div className="loading-spinner">
        <p>Loading...</p>
      </div>
    );
  }

  const { product_code, name = 'Product', description = 'Product description not available.' } = productData.data; // Provide defaults
  const related_products = productData.related_product.filter(
    (product) => product.product_code !== product_code
  );

  return (
    <div>
      <Helmet>
        <title>{name} - Jaipur Masale</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`https://jaipurmasale.com/product/${productId}`} />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}> {/* Add Suspense */}
        <Breadcrum product={productData.data} />
        <ProductDisplay
          product={productData.data}
          selectedSize={sizeMap[selectedSize]} // Convert shorthand to descriptive for display
          onSizeChange={handleSizeChange}
        />
        <RelatedProducts relatedProducts={related_products} />
        <DescriptionBox product={productData.data} />
        <ContactUs />
      </Suspense>
    </div>
  );
};

export default Product;
