import React, { useContext, useRef, useState, useEffect } from 'react';
import './Navbar.css';
import cart_icon from '../Assets/cart_icon.webp';
import nav_dropdown from '../Assets/nav_dropdown.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import { useNavigate } from 'react-router';
import jaipur_masale from '../Assets/jaipur_masale.webp';
import { FaSearch } from 'react-icons/fa';

const Navbar = ({ isHomePage }) => {
  const [menu, setMenu] = useState("shop");
  const { clearCartItems, cartItems, getTotalCartItems } = useContext(ShopContext);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [scrollUp, setScrollUp] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const menuRef = useRef();
  const navigate = useNavigate();
  const lastScrollY = useRef(window.scrollY);

  const dropdownToggle = (e) => {
    menuRef.current.classList.toggle('nav-menu-visible');
    e.target.classList.toggle('open');
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(!!localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    getTotalCartItems();
  }, [cartItems, getTotalCartItems]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    clearCartItems();
    navigate('/');
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollUp(currentScrollY <= lastScrollY.current || currentScrollY <= 100);
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
  };

  return (
    <div>
    <div className={`navbar ${scrollUp ? 'visible' : 'hidden'} ${isHomePage ? 'home' : 'not-home'}`}>
      <div className='navbar-horizontal'>
      <Link to='/' onClick={() => setMenu("shop")} className="nav-logo">
        <img src={jaipur_masale} alt="Logo" />
      </Link>
      <img onClick={dropdownToggle} className='nav-dropdown' src={nav_dropdown} alt="Menu" />

      <ul ref={menuRef} className="nav-menu">
        <li onClick={() => setMenu("Home")}>
          <Link to='/'>Home</Link>
          {menu === "Home" && <hr />}
        </li>
        <li onClick={() => setMenu("organic_khada_masala")}>
          <Link to="/organic-khada-masala">Khada Masala</Link>
          {menu === "organic_khada_masala" && <hr />}
        </li>
        <li onClick={() => setMenu("organic_powdered_masala")}>
          <Link to='/organic-powdered-masala'>Powdered Masala</Link>
          {menu === "organic_powdered_masala" && <hr />}
        </li>
        <li onClick={() => setMenu("premium_dry_fruits_seeds")}>
          <Link to='/premium-dry-fruits-berries'>Dry Fruits & Berries</Link>
          {menu === "premium_dry_fruits_seeds" && <hr />}
        </li>
        <li onClick={() => setMenu("Blog")}>
          <Link to='/Blog'>Blog</Link>
          {menu === "Blog" && <hr />}
        </li>
        <li onClick={() => setMenu("contact_us")}>
          <Link to='/contact-us'>Contact Us</Link>
          {menu === "contact_us" && <hr />}
        </li>

      </ul>

      <div className="nav-login-cart">
        {!isAuthenticated ? (
          <Link to='/Login'><button>Login</button></Link>
        ) : (
          <div className="profile-dropdown">
            <FontAwesomeIcon icon={faUser} style={{ color: "#000", fontSize: '24px' }} />
            <div className="dropdown-content">
              <button onClick={handleLogout}>Logout</button>
            </div>
          </div>
        )}
        <Link to='/cart'><img src={cart_icon} alt="Cart" /></Link>
        <div className="nav-cart-count">{getTotalCartItems()}</div>
      </div>
      </div>

      <form onSubmit={handleSearchSubmit} className="nav-search-form">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="nav-search-input"
        />
        <button type="submit" className="nav-search-button">
          <FaSearch />
        </button>
      </form>
    </div>
  </div>
  );
};

export default Navbar;
